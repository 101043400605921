
import VueTypes from 'vue-types';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    intro: VueTypes.string,
    button: VueTypes.object,
    buttonLabel: VueTypes.string,
    visual: VueTypes.arrayOf({
      kind: VueTypes.string,
    }),
  },
  computed: {
    fillColor() {
      if (this.$site === 'yim') {
        return '#0752C7';
      }

      return '#FF6B33';
    },
    gradientColor() {
      if (this.$site === 'yim') {
        return '#002395';
      }

      return '#F42740';
    },
    screenSmall() {
      return this.$store.state.viewport.width >= breakpoints.s;
    },
    screenSuperSmall() {
      return this.$store.state.viewport.width < breakpoints.xs;
    },
  },
  methods: {
    onScrollButtonClick() {
      const el = document.querySelector('.module');
      this.$nuxt.$emit('scrollTo', el.getBoundingClientRect().top - 112, 1);
    },
  },
};
