import { render, staticRenderFns } from "./HomeHeader.vue?vue&type=template&id=39ab24b9&scoped=true"
import script from "./HomeHeader.vue?vue&type=script&lang=js"
export * from "./HomeHeader.vue?vue&type=script&lang=js"
import style0 from "./HomeHeader.vue?vue&type=style&index=0&id=39ab24b9&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ab24b9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Visual: require('/workspace/app/components/media/Visual.vue').default,PillButton: require('/workspace/app/components/buttons/PillButton.vue').default,CustomLink: require('/workspace/app/components/links/CustomLink.vue').default,Shape: require('/workspace/app/components/icons/Shape.vue').default})
