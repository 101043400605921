
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    body: VueTypes.string,
    url: VueTypes.string,
  },
};
