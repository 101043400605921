
import seomaticMixin from '@/mixins/seomatic';
import homeQuery from '@/gql/queries/pages/home.gql';

export default {
  mixins: [seomaticMixin],
  asyncData({ $cms, $site, $slug }) {
    return $cms.query({
      query: homeQuery,
      variables: { site: $site, slug: $slug },
      mandatoryKeys: ['entry'],
    });
  },
  head() {
    return this.formatHead(this.entry?.seo);
  },
  created() {
    if (this.$site === 'mvm') {
      this.$store.commit('nav/navLight');
    }
  },
  beforeDestroy() {
    if (this.$site === 'mvm') {
      this.$store.commit('nav/navDark');
    }
  },
};
