import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3b958ffa"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHeader: require('/workspace/app/components/headers/HomeHeader.vue').default,HomeHighlight: require('/workspace/app/components/home/HomeHighlight.vue').default,ModuleTemplate: require('/workspace/app/components/templates/ModuleTemplate.vue').default,Page: require('/workspace/app/components/layout/Page.vue').default})
